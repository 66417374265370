
















































































































































import Vue from "vue";
import defaultComponent from "../utils/defaultComponent";
import humanizeString from "humanize-string";
import merge from "../utils/merge";

export default defaultComponent.apply({
  props: ["xData"],
  data() {
    return {
      humanizeString,
      myKey: "",
      hideHeader: false,
      stepperContentStyle: {
        "transition-property": "none"
      }
    };
  },
  computed: {
    currentTabIndex() {
      let index = -1;
      for (const itemKey in this.options.content.items) {
        index++;
        if (this.tabKey(itemKey) === this.currentTab.value) {
          return index;
        }
      }
      return -1;
    },
    isLastTab() {
      return (
        this.currentTabIndex ===
        Object.keys(this.options.content.items).length - 1
      );
    }
  },
  methods: {
    async initialize() {
      this.myKey = this._uid;
      for (const itemName in this.options.content.items) {
        this.options.content.items[itemName] = merge.clone(
          {
            content: {}
          },
          this.options.content.items[itemName]
        );
      }
      if (this.options.attrs && this.options.attrs.defaultIndex) {
        this.scrollTo(Number(this.options.attrs.defaultIndex));
      }
      if (this.options.attrs.hideHeader) {
        this.hideHeader = this.options.attrs.hideHeader;
      }
      await new Promise(resolve => setTimeout(resolve, 1000));
      Vue.delete(this.stepperContentStyle, "transition-property");
    },
    onItemChanged(event, currentItem) {
      if (!this.options.content.horizontal) {
        if (currentItem) {
          const key = currentItem.getAttribute("data-key");
          this.currentTab.value = key;
        }
      }
    },
    next() {
      const index = this.currentTabIndex + 1;
      if (index >= Object.keys(this.options.content.items).length) {
        return;
      }
      this.scrollTo(index);
    },
    back() {
      const index = this.currentTabIndex - 1;
      if (index < 0) {
        return;
      }
      this.scrollTo(index);
    },
    scrollTo(index) {
      this.currentTab.value = this.tabKey(
        Object.keys(this.options.content.items)[index]
      );
    },
    tabKey(key) {
      return `tabs-${this.myKey}-${key}`;
    },
    setHideHeader(value = false) {
      this.hideHeader = value;
    },
    getCurrentTabName() {
      const arrTabKeys = Object.keys(this.options.content.items);
      const items = this.options.content.items;
      const currentKey = arrTabKeys[this.currentTabIndex];
      const item: any = items[currentKey];
      if (item.content.header) {
        return item.content.header;
      }
      return this.humanizeString(currentKey);
    }
  },
  extraOptions: {
    defaultClass: "scrollableTabs",
    forwardStates: {
      xCurrentTab: ["currentTab", null]
    }
  }
});
