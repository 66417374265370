var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-fit flex-column d-flex"},[_c('scrollactive',{attrs:{"active-class":"active","scrollContainerSelector":".scrollTabsContainer","modifyUrl":false,"offset":130,"highlightFirstItem":true},on:{"itemchanged":_vm.onItemChanged}},[_c('v-tabs',_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.hideHeader),expression:"!hideHeader"}],model:{value:(_vm.currentTab.value),callback:function ($$v) {_vm.$set(_vm.currentTab, "value", $$v)},expression:"currentTab.value"}},'v-tabs',_vm.options.attrs,false),[_vm._l((Object.entries(
          Object.entries(_vm.options.content.items)
        )),function(ref){
        var i = ref[0];
        var ref_1 = ref[1];
        var key = ref_1[0];
        var item = ref_1[1];
return [(
            !item.ext ||
            (!item.ext.hidden &&
              (!item.ext.condition || item.ext.condition(_vm.self)))
          )?_c('v-tab',{key:key,staticClass:"scrollactive-item",attrs:{"href":("#tabs-" + _vm.myKey + "-" + key),"data-index":i,"data-key":("tabs-" + _vm.myKey + "-" + key)}},[_vm._v(_vm._s(item.content.header ? item.content.header : _vm.humanizeString(key))+" ")]):_vm._e()]})],2)],1),(_vm.options.content.horizontal)?_c('div',{staticClass:"flex-fit mt-1 full-height"},[_c('v-stepper',{attrs:{"non-linear":""},model:{value:(_vm.currentTab.value),callback:function ($$v) {_vm.$set(_vm.currentTab, "value", $$v)},expression:"currentTab.value"}},[_vm._l((Object.entries(
          Object.entries(_vm.options.content.items)
        )),function(ref){
        var i = ref[0];
        var ref_1 = ref[1];
        var key = ref_1[0];
        var item = ref_1[1];
return [(
            !item.ext ||
            (!item.ext.hidden &&
              (!item.ext.condition || item.ext.condition(_vm.self)))
          )?_c('div',_vm._b({key:key,attrs:{"id":("tabs-" + _vm.myKey + "-" + key)}},'div',item.attrs,false),[_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"pa-0",style:(_vm.stepperContentStyle),attrs:{"step":("tabs-" + _vm.myKey + "-" + key)}},[_c('v-card',{staticClass:"px-4",attrs:{"flat":""}},[_c('div',[(
                      item.content.title &&
                      _vm.currentTab.value === ("tabs-" + _vm.myKey + "-" + key)
                    )?_c('v-card-title',{staticClass:"pa-0 mb-3 mt-3"},[_vm._v(" "+_vm._s(item.content.title)+" ")]):_vm._e(),_vm._t(("tabs." + key),function(){return [(item.content.type)?_c(item.content.type,_vm._b({tag:"component",attrs:{"x-context":_vm.self}},'component',
                        item.content.makeAttrs
                          ? item.content.makeAttrs(item.content.attrs, _vm.self)
                          : item.content.attrs
                      ,false)):_vm._e()]})],2)])],1)],1)],1):_vm._e()]})],2)],1):_c('div',{staticClass:"scrollTabsContainer flex-fit mt-6"},[_vm._l((Object.entries(
        Object.entries(_vm.options.content.items)
      )),function(ref){
      var i = ref[0];
      var ref_1 = ref[1];
      var key = ref_1[0];
      var item = ref_1[1];
return [(
          !item.ext ||
          (!item.ext.hidden &&
            (!item.ext.condition || item.ext.condition(_vm.self)))
        )?_c('div',_vm._b({key:key,attrs:{"id":("tabs-" + _vm.myKey + "-" + key)}},'div',item.attrs,false),[_c('v-card',{staticClass:"px-4",attrs:{"flat":""}},[_c('div',[(item.content.title)?_c('v-card-title',{staticClass:"pa-0 mb-3"},[_vm._v(" "+_vm._s(item.content.title)+" ")]):_vm._e(),_vm._t(("tabs." + key),function(){return [(item.content.type)?_c(item.content.type,_vm._b({tag:"component",attrs:{"x-context":_vm.self}},'component',
                  item.content.makeAttrs
                    ? item.content.makeAttrs(item.content.attrs, _vm.self)
                    : item.content.attrs
                ,false)):_vm._e()]})],2)])],1):_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }